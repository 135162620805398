.login{
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 50px;
    height: 100vh;
    width: 80%;
    margin: auto;
    .right{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .email-support{
        color:#2642bb;
        font-size: 15px;
        letter-spacing: 1px;
        // font-weight: 600;
        transition: all 0.3s ease;
        &:hover{
            color: #394b9f;
        }
    }
    .login-card{
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: #ffff;
        // background-color: #f9f6f6 !important;
        width: 450px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
        .login-form{
            width: 100%;
            // width: 400px;
            .login-title{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 20px;
                .login-title-text{
                    color: #74788d;
                    font-size: 18px;
                }
                .login-icon{
                    color: rgb(57, 75, 159);
                }
            }
            .anticon.ant-input-password-icon{
                color: rgba(0, 0, 0, 0.45);
                cursor: pointer;
                transition: all 0.3s;
                &:hover{
                    color: rgba(0, 0, 0, 0.88);
                }
            }
            .ant-input-affix-wrapper,
            .ant-input{
                color: rgba(0, 0, 0, 0.88);
                background: #fff;
                border-radius: 8px;
                border-color: #d9d9d9;
            }
            .ant-row{
                width: 100%;
            }
            .forgot-password{
                color: rgb(57, 75, 159);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                .forgot-icon{
                    margin-right: 5px;
                }
            }
            .ant-checkbox-wrapper{
                color: rgba(0, 0, 0, 0.88);
                .ant-checkbox-inner{
                    border-color: #d9d9d9;
                    background-color: #fff;
                    &:hover{
                        border-color: #d9d9d9;
                    }
                }
                .ant-checkbox-checked{
                    .ant-checkbox-inner{
                        background-color: rgb(57, 75, 159);
                        border-color: rgb(57, 75, 159);
                    }
                }
            }
            .login-form-button{
                width: 100%;
                border-radius: 8px;
                height: 40px;
                background-color: rgb(57, 75, 159);
                color: white;
                &:hover{
                    background: rgba(57, 76, 159, 0.821);
                }
            } 
        }
    }
    .left{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #dee2e6;
    width: 50%;
    height: 50vh;
        img{
            width: 400px;
            object-fit: cover;
        }
    }
    @media screen and (max-width: 992px) {
        flex-direction: column;
        gap: 0px;
        .left{
            display: none;
        }
        .right{
            width: 100%;
            height: 100%;
            margin: auto ;
            
        }
    }
    @media screen and (max-width: 576px) {
        .login-card{
                width: 100%;
                .ant-card-body{
                    padding: 1.5rem 1.1rem !important;
                }
                .login-form{
                    width: 100%;
                    .login-title-text{
                        font-size: 16px;
                    }
                    .forgot-password{
                        font-size: 13px;
                    }
                    .remember-check{
                        font-size: 13px;
                    }
                    .email-support{
                        font-size: 13px;
                    }
                    .login-form-button{
                        width: 100%;
                    }
                }
            }
    }
}






