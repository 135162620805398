.dashboard-admin{
    .dashboard-row{
        .dashboard-card{
            transition: all 0.5s;
            &:hover{
                .dashboard-card-img{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            .ant-card-body{
                position: relative;
                padding: 1.125rem 0.8125rem;
                border-radius: 0.625rem;
                // background: #21262E;
                // margin-bottom: 1.875rem;
                height: calc(100% - 1.875rem);
                display: flex;
                overflow: hidden;
                align-items: center;
                .dashboard-card-img{
                    img{
                        position: absolute;
                        top: 0.9375rem;
                        right: 0;
                        opacity: 0.3;
                        -webkit-transition: all 0.5s;
                        -ms-transition: all 0.5s;
                        transition: all 0.5s;
                    }
                }
                .dashboard-card-icon{
                    height: 3.125rem;
                    width: 3.125rem;
                    line-height: 3.125rem;
                    background: white;
                    border-radius: 0.625rem;
                    text-align: center;
                    line-height: 3.4375rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 1.875rem;
                        height: 1.875rem;
                    }
                }
                .dashboard-card-info{
                    .dashboard-card-count{
                        .dashboard-card-count-text{
                            color: #fff;
                            font-size: 1.75rem;
                            font-weight: 700;
                            color: white;
                            margin: 0;
                            line-height: 1.2;
                        }
                    }
                    .dashboard-card-title{
                        .dashboard-card-title-text{
                            color: #fff;
                            font-size: 0.875rem;
                            font-weight: 600;
                            color: white;
                            margin: 0;
                        }
                   }
                }
            }
        }
    }
}