    .min-layout{
        .ant-input-group-addon{           
            display: none !important;
        }
        .img{
            width: 70px;
            height: 90px;
            cursor: pointer;
        }
        .branch-select{
            .ant-select-selector{
                border-radius: 8px;
                gap: 5px;
                font-size: 14px;
                // height: 40px;
                line-height: 40px;
                padding: 0 10px;
            }
        }
        .fullscreen{
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                cursor: pointer;
                transition: 0.3s ease;
                &:hover{
                    transform: scale(1.2);
                }
            }
        }
        .own-header-space{
            gap: 20px;
        }
        .logo{
            height: 80px;
            // width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #f1f5f7bf;
            margin-bottom: 5px;
            overflow: hidden;
            // img{
            //     width: 200px;
            //     object-fit: contain;
            // }
        }
        .admin-dropdown{
            // color: #636e75;
            opacity: 0.8;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
        }
    }
    .search-icon{
        svg{
            display: none ;
        }
    }
    .site-layout,
     .search-modal-container{
        .ant-input-group-addon{           
            display: none !important;
        }
            .dark-search{
                .ant-input-affix-wrapper{
                    background: #464f5b ;
                    color: rgba(255,255,255,.6);
                    .ant-input{
                        background: #464f5b ;
                        color: rgba(255,255,255,.6);
                        &::placeholder{
                            color: rgba(255,255,255,.6);
                        }
                    }
                }
            }
            .light-search{
                .ant-input-affix-wrapper{
                    background: #f1f5f7 !important;
                    color: #505d69;
                    .ant-input{
                       background: #f1f5f7 !important;
                        color: #505d69;
                        &::placeholder{
                            color: #505d69;
                        }
                    }
                }
            }
            .search-input,
            .search-input-admin,
            .search-input-modal{
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-input-affix-wrapper{
                    border:none !important;
                    border-radius: 8px;
                }
            }
        }
        .dropdown-link-responsive{
            display: none !important;
        }
        .own-header-space{
            cursor: pointer;
        }
        .badge-style{
            .ant-badge-count{
                background: #EF5457 !important;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                text-align: center;
                right: 8px;
                top: 5px;
                color: white;
                .ant-scroll-number-only{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: normal;
                    font-size: 12px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .dropdown-style-own{
            .ant-dropdown-menu{
                padding: 0 !important;
            }
            .notification-card{
                border-bottom: 1px solid #f1f5f7d9;
                width: 100%;

                .notification-container{
                    padding: 10px 2px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 15px;

                    .img-notification{
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        // background: #f1f5f7bf;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            // border-radius: 50%;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    .content-notification{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        .info-notification{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
    
                            .name-notification{
                                font-size: 15px;
                                font-weight: 500;
                                // margin-bottom: 5px;
                            }
                            .date-notification{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 5px;
                                .time-notification{
                                    font-size: 13px;
                                    font-weight: 400;
                                }
                                .ant-badge{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                        .title-notification{
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }

            }
            .ant-dropdown-menu-item-group-list{
                margin: 0 !important;
                width:250px;
                max-height: 250px;
                overflow-y: auto;
            }
            .ant-dropdown-menu-item-group-title{
                margin: 0 !important;
                font-size: 17px;
                // color: #505d69;
                font-weight: 500;
                text-align: center;
                border-bottom: 1px solid #8b889074;
                width: 100%;
                padding: 12px;
                margin-bottom: 10px;
            }
        }
        .logo-two{
            display: none;
        }
        @media screen and (max-width: 768px) {
            .search-input-admin{
                width: 180px !important;
            }
            .dropdown-link-responsive{
                display: flex !important;
            }
        .site-layout-responsive{
            display: none !important;
        }
        .own-header-space{
            gap: 10px !important;
        }
        .ant-layout-header{
            padding: 0 10px !important;
        }
        .search-icon{
            svg{
                display: block !important;
            }
        }
        .ant-layout-content{
            padding: 20px 15px !important;
        }
        .language{
            display: flex;
            align-items: center;
            justify-content: center;
            // .lang-dropdown{
            //     margin-left: 10px;
            // }
        }
        .lang-dropdown{
            svg{
                font-size: 25px;
            }
        }
        .search-input,
        .lang-text,
        .admin-dropdown{
            display: none !important;
        }
        .search-input{
            width: 150px;
        }
    }
    .link-header{
        width: 100%;
        font-size: 21px;
        font-weight: 500;
        // text-transform: uppercase;
        padding-bottom: 20px;
        // color: #000000e0 !important;
        border-bottom: 1px solid #000000e0;
    }
    .activeLink{
        color: #4020a5  !important;

    }
    @media screen and (max-width: 500px) {
        .img{
            width: 35px !important;
            height: 40px !important;
        }
        .fullscreen{
            display: none !important;
        }
        .logo-one{
            display: none;
        }
        .logo-two{
            display: block;
        }
    }

    .branch-select-popup{
        .rc-virtual-list-holder-inner{
            .ant-select-item-option{
                width: 100%;
            }
            align-items: start;
        }
        .ant-select-item-option-content{
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 10px;
            font-size: 14px;
            height: 20px;
        }
    }