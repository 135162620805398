.ant-card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem !important;
}
.analytics-card,
.card-graph{
    .dark-chart{
        .apexcharts-menu-item{
            color: #000 !important;
            font-size: 12px !important;
            text-align: center;
        }
        tspan{
            fill: #fff !important;
        }
        text{
            fill: #fff !important;
        }
        .apexcharts-legend-text{
            color: #fff !important;
        }
    }
}
.ant-space-item{
    display: flex;
    align-items: center;
    justify-content: center;
}


.card-graph{
    .ant-segmented-group{
      .ant-segmented-item{
        border: none;
        color: #0bb197;
        background-color: rgba(11, 177, 151, 0.1);
        // background-color:#9cf0e496;
        border-color: transparent;
        font-size: 14px;
        font-weight: 600;
        transform: none !important;
        // border-radius: 0;
        &:active, 
        &:focus,
        &:hover{
          background-color: #0bb197 ;
          color: #fff !important;
        }
      }
      .ant-segmented-item-selected{
        background-color: #0bb197;
        color: #fff;
      }
    }
    .card-title{
      font-size: 15px;
      margin: 0 0 7px 0;
      font-weight: 500;
    }
    .card-footer{
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;
      border-top: 1px solid #f1f5f7;
      padding-top: 20px;
        .title-footer-item{
          color: #74788d;
          font-size: 13px;
          font-weight: 500;
          text-transform: capitalize;
        }
        .count-footer-item{
          color: #343a40;
          font-size: 15px;
          font-weight: 500;
        }
        .circle{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #0bb197;
          display: inline-block;
        }
      }
    // }
  }
  .table-app{
    .ant-table{
       .ant-table-thead{
           background: transparent;
           .ant-table-cell{
               background: transparent;
               color: #505d69;
               font-size: 0.875rem;
           }
       }
    .ant-table-tbody{
        .ant-table-row{
            td{
                font-size: 14px;
                cursor: pointer;
            }
            &:nth-child(even){
                td{
                    background: #f8f9fa;
                    color: #505d69;
                    font-weight: 500;
                    font-size: 14px;
                }
            }
            &:hover{
                td{
                    background: #f8f9fa;
                }
            }
        }
    }
}
}
.dark-table{
.ant-table{
    .ant-table-thead{
        .ant-table-cell{
            color: #fff;
        }
    }
    .ant-table-tbody{
        .ant-table-row{
            &:nth-child(even){
                td{
                    background: #505d69;
                    color: #fff;
                }
            }
            &:hover{
                td{
                    background: #505d69;
                }
            }
        }
    }
}
}

.ant-popover-title{
    min-width: 10px !important;
  }

  .form-btns{
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    // margin-top: 20px;
    .ant-btn{
        &.ant-btn-primary{
        margin-top: 20px;
      min-width: 100px;
    }
  }
  }


  .form-subfeatures{
    .ant-form-item-required{
        width: 100%;
    }
  }


  .btn-add-admin{
    display: flex;
    align-items: center;
    justify-content: center; 
  }