.heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: auto;
    .heading-title{
        font-size: 18px;
        font-weight: 600;
        // color: #343a40;
    }
    .heading-sub{
        // color: #505d69;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        .heading-subtitle{
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
        }
    }
    @media screen and (max-width: 768px) {
        .heading-sub{
            display: none;
        }
    }
}